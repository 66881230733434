<template>
  <div
    class="page_"
    @click="
      showArea = false
      nowIndexTab = -1
    "
  >
    <div class="tab_">
      <p class="flex_ tab_f">
        <span>{{ $t('ML[2]') || '大陆代表性网络医院' }}</span>
        <span>|</span>
        <span
          @click.stop="
            showArea = !showArea
            nowIndexTab = -1
          "
          ><span>{{ province || this.$t('ML[6]') || '北京市' }}</span
          ><span class="icon_arrow"></span
        ></span>
        <span @click.stop="showSelect(0)"
          ><span>{{ property }}</span
          ><span class="icon_arrow"></span
        ></span>
        <span @click.stop="showSelect(1)"
          ><span>{{ levelName }}</span
          ><span class="icon_arrow"></span
        ></span>
        <span @click.stop="showSelect(2)"
          ><span>{{ className }}</span
          ><span class="icon_arrow"></span
        ></span>
        <span class="input_">
          <input
            type="text"
            :placeholder="$t('ML1[38]') || '请输入医院名称'"
            v-model="provider_name"
            @input="findHospitalByName"
          />
        </span>
      </p>
      <div class="selectcon_" v-if="showArea">
        <div class="flex_l">
          <p style="width:100%;font-weight:bold;margin-bottom:0;">
            {{ $t('ML[3]') || '请选择省' }}
          </p>
          <span
            v-for="(item, index) in provinceData"
            :key="index"
            @click.stop="createCityList(item, index)"
            :class="{ on: pindex === index }"
            >{{ $i18n.locale == 'zh-cn' ? item.fdesc : item.sdesc }}</span
          >
        </div>
        <div class="flex_r">
          <p style="width:100%;font-weight:bold;margin-bottom:0;">
            {{ $t('ML[4]') || '请选择市' }}
          </p>
          <span
            v-for="(item, index) in cityData"
            :key="index"
            @click.stop="selectCity(item, index)"
            :class="{ on: cityIndex === index }"
            >{{ $i18n.locale == 'zh-cn' ? item.fdesc : item.sdesc }}</span
          >
        </div>
      </div>
      <!-- 性质弹窗 -->
      <div v-show="0 === nowIndexTab" class="selectcon_ selectcon_1">
        <div class="list_">
          <span
            v-for="(item, index) in propertyList"
            :key="item.id"
            @click.stop="selectProperty(index, item.name, item.code)"
            :class="{ on: index === nowindexP }"
            >{{ item.name }}</span
          >
        </div>
      </div>
      <!-- 等级弹窗 -->
      <div v-show="1 === nowIndexTab" class="selectcon_ selectcon_2">
        <div class="list_">
          <span
            v-for="(item, index) in levelList"
            :key="item.id"
            @click.stop="selectLevel(index, item.name, item.code, item)"
            :class="{ on: index === nowindexL }"
            >{{ $i18n.locale == 'zh-cn' ? item.name : item.nameEn }}</span
          >
        </div>
      </div>
      <!-- 类别弹窗 -->
      <div class="selectcon_ selectcon_3" v-show="2 === nowIndexTab">
        <div class="list_">
          <span
            v-for="(item, index) in classList"
            :key="item.id"
            @click.stop="selectClass(index, item.name, item.code, item)"
            :class="{ on: index === nowindexC }"
            >{{ $i18n.locale == 'zh-cn' ? item.name : item.nameEn }}</span
          >
        </div>
      </div>
    </div>
    <div class="h_list">
      <div class="hitem" v-for="(item, index) in datalist" :key="index">
        <img
          :src="item.fileUrl == null ? defaultImg : item.fileUrl"
          alt=""
          @error="defImg(index)"
        />
        <div class="item_r">
          <p class="tit_">
            {{ item.longName }}<span>{{ item.providerLevel }}</span>
          </p>
          <p class="prop_">
            {{ $i18n.locale == 'zh-cn' ? '医院性质' : 'providerType' }}：{{
              item.providerType
            }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
              $i18n.locale == 'zh-cn' ? '医院类型' : 'providerCatg'
            }}：{{ item.providerCatg }}
          </p>
          <p class="des_"><span v-html="ellipsis(item.detailsDisp)"></span></p>
        </div>
      </div>
    </div>
    <div v-if="datalist && datalist.length > 0" class="page_wrap_">
      <a-pagination @change="handlePageChange" :total="total" :pageSize="10" />
    </div>
    <div class="noh" v-if="datalist.length === 0">
      <img src="../../../assets/medilink/noh.png" alt="" />
      <span>暂无数据</span>
    </div>
    <div class="fixbg1" v-if="isLoading">
      <a-spin />
    </div>
  </div>
</template>
<script>
import { setTimeout, clearTimeout } from 'timers'
export default {
  data() {
    return {
      text: "<p style='color: red;'>你好啊</p>",
      defaultImg: require('../../../assets/medilink/hospital.png'),
      provinceData: [],
      pindex: 0,
      province: '',
      property: this.$t('ML1[0]') || '请选择性质',
      levelName: this.$t('ML1[5]') || '请选择等级',
      className: this.$t('ML1[16]') || '请选择类别',
      cityData: [],
      cityIndex: '',
      showArea: false,
      propertyList: [
        { id: 0, name: this.$t('ML1[1]') || '全部', code: '0000' },
        { id: 1, name: this.$t('ML1[2]') || '民营', code: '1' },
        { id: 2, name: this.$t('ML1[3]') || '公立', code: '5' },
        { id: 3, name: this.$t('ML1[4]') || '外资', code: '10' }
      ],
      levelList: [],
      classList: [],
      nowindexP: 0,
      nowindexC: 0,
      nowindexL: 0,
      nowIndexTab: -1,
      state: 'BJ',
      dist: '',
      provider_type: '',
      provider_level: '',
      provider_catg: '',
      provider_name: '',
      datalist: [],
      total: 0,
      current: 1,
      isLoading: false,
      isShowImage: true
    }
  },
  mounted() {
    this.fetchData()
    this.fetchHospitalList()
  },
  filters: {
    ellipsis(value) {
      if (!value) return ''
      if (value.length > 200) {
        return value.slice(0, 200) + '...'
      }
      return value
    }
  },
  methods: {
    findHospitalByName() {
      clearTimeout(timer)
      let timer = setTimeout(() => {
        if (this.provider_name.trim() != '') {
          this.fetchHospitalList()
        }
      }, 800)
    },
    ellipsis(value) {
      if (!value) return ''
      if (value.length > 200) {
        return value.slice(0, 200) + '...'
      }
      return value
    },
    fetchData() {
      this.$apis.getReferenceInfo({ refGrp: 'STATE_CHN' }).then(res => {
        if (res.data.code === 200) {
          this.provinceData = res.data.data
          this.provinceData.unshift({
            fdesc: '全部',
            nameEn: 'all',
            refCd: '0000',
            medCount: '0'
          })
        }
      })
      // 医院类别
      this.$apis.getReferenceInfo({ refGrp: 'PROVTYPDISP' }).then(res => {
        if (res.data.code === 200) {
          this.classList = res.data.data.map((item, index) => {
            let data = {
              id: index + 1,
              name: item.fdesc,
              nameEn: item.sdesc,
              code: item.refCd
            }
            return data
          })
          this.classList.unshift({
            id: 0,
            name: '全部',
            nameEn: 'all',
            code: '0000'
          })
        }
      })
      // 医院等级
      this.$apis.getReferenceInfo({ refGrp: 'PROVIDER_LEVEL' }).then(res => {
        if (res.data.code === 200) {
          this.levelList = res.data.data.map((item, index) => {
            let data = {
              id: index + 1,
              name: item.fdesc,
              nameEn: item.sdesc,
              code: item.refCd
            }
            return data
          })
          this.levelList.unshift({
            id: 0,
            name: '全部',
            nameEn: 'all',
            code: '0000'
          })
        }
      })
    },
    createCityList(item, index) {
      this.pindex = index
      this.province = this.$i18n.locale == 'zh-cn' ? item.fdesc : item.sdesc
      this.state = item.refCd
      if (index === 0) {
        this.state = ''
        this.dist = ''
        this.showArea = false
      } else {
        this.$apis
          .getReferenceInfo({ refGrp: 'DIST_CHN_' + item.refCd })
          .then(res => {
            if (res.data.code === 200) {
              this.cityData = res.data.data
              this.cityData.unshift({
                fdesc: '全部',
                refCd: '0000',
                medCount: '0'
              })
            }
          })
      }
      this.fetchHospitalList()
    },
    selectCity(item, index) {
      this.cityIndex = index
      this.province = item.fdesc
      this.dist = item.refCd
      this.showArea = false
      this.fetchHospitalList()
    },
    showSelect(index) {
      if (this.showArea === true) {
        this.showArea = false
      }
      if (index != this.nowIndexTab) {
        this.nowIndexTab = index
      } else {
        this.nowIndexTab = -1
      }
    },
    selectProperty(index, name, code) {
      if (code === '0000') {
        this.provider_type = ''
      } else {
        this.provider_type = name
      }
      this.property = name
      this.nowindexP = index
      this.nowIndexTab = -1
      this.fetchHospitalList()
    },
    closeBg1() {
      this.nowIndexTab = -1
    },
    selectClass(index, name, code, item) {
      if (code === '0000') {
        this.provider_catg = ''
        if (this.$i18n.locale == 'zh-cn') {
          this.className = '全部'
        } else {
          this.className = 'all'
        }
      } else {
        if (this.$i18n.locale == 'zh-cn') {
          this.provider_catg = name
          this.className = name
        } else {
          this.provider_catg = item.nameEn
          this.className = item.nameEn
        }
      }
      this.nowindexC = index
      this.nowIndexTab = -1
      this.fetchHospitalList()
    },
    selectLevel(index, name, code, item) {
      if (code === '0000') {
        this.provider_level = ''
        if (this.$i18n.locale == 'zh-cn') {
          this.levelName = '全部'
        } else {
          this.levelName = 'all'
        }
      } else {
        if (this.$i18n.locale == 'zh-cn') {
          this.provider_level = name
          this.levelName = name
        } else {
          this.provider_level = item.nameEn
          this.levelName = item.nameEn
        }
      }
      this.nowindexL = index
      this.nowIndexTab = -1
      this.fetchHospitalList()
    },
    fetchHospitalList() {
      let requestdata1 = {
        state: this.state,
        pageNum: this.current,
        pageSize: 10,
        dist: this.dist,
        providerType: this.provider_type,
        providerLevel: this.provider_level,
        providerCatg: this.provider_catg,
        providerName: this.provider_name
      }
      this.isLoading = true
      this.$apis.getProviderPageListToWeb(requestdata1).then(res => {
        this.isLoading = false
        if (res.data.returnCode === '0000') {
          this.total = res.data.content.total
          this.datalist = res.data.content.list.map(item => {
            if (this.$i18n.locale != 'zh-cn') {
              item.detailsDisp = item.detailsDispEng
              item.longName = item.longNameEng
              item.providerCatg = item.providerCatgEng
              item.providerLevel = item.providerLevelEng
              item.providerType = item.providerTypeEng
            }
            return item
          })
        }
      })
    },
    handlePageChange(e) {
      this.current = e
      this.fetchHospitalList()
    },
    defImg(index) {
      this.datalist[index].fileUrl = this.defaultImg
    }
  }
}
</script>
<style lang="scss" scoped>
.tab_ {
  width: 1000px;
  margin: 0 auto;
  font-size: 14px;
  color: #000;
  position: relative;
  .selectcon_ {
    position: absolute;
    top: 37px;
    left: 170px;
    right: 0;
    background-color: #fff;
    padding: 10px;
    width: 640px;
    box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1);
    &.selectcon_1 {
      left: 340px;
    }
    &.selectcon_2 {
      left: 400px;
    }
    &.selectcon_3 {
      left: 460px;
    }
    .flex_l,
    .flex_r,
    .list_ {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
    }
    span {
      width: 25%;
      text-align: left;
      padding: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      &.on {
        color: #00a63c;
      }
    }
  }
}
.h_list {
  width: 1000px;
  margin: 0 auto;
  font-size: 14px;
  color: #000;
  .hitem {
    display: flex;
    margin-bottom: 10px;
    box-sizing: content-box;
    border: 1px solid #fff;
    img {
      width: 280px;
      height: 190px;
      object-fit: cover;
    }
    &:hover {
      border: 1px solid #00a63c;
    }
    .item_r {
      width: 720px;
      padding: 25px 30px;
      background-color: #fbfbfb;

      p {
        margin: 0;
        &.tit_ {
          font-weight: bold;
          font-size: 18px;
          display: flex;
          // align-items: center;
          span {
            display: inline-block;
            margin-top: 4px;
            padding: 0 10px;
            font-size: 11px;
            color: #00a63c;
            border: 1px solid #00a63c;
            height: 18px;
            margin-left: 10px;
            line-height: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 60px;
            margin-left: 10px;
          }
        }
        &.prop_ {
          font-size: 14px;
          margin-top: 3px;
        }
        &.des_ {
          font-size: 12px;
          color: #666666;
          margin-top: 7px;
        }
      }
    }
  }
}
input {
  border: none;
  background-color: transparent;
}
.page_wrap_ {
  width: 1000px;
  margin: 20px auto;
  text-align: right;
}
.flex_ {
  display: flex;
  align-items: center;
  &.tab_f {
    margin-bottom: 30px;
    > span {
      &:first-child {
        padding-left: 0;
      }
      cursor: pointer;
      padding: 0px 23px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;
      .icon_arrow {
        width: 7px;
        height: 5px;
        display: inline-block;
        background: url(../../../assets/medilink/downiconon.png) center center
          no-repeat;
        background-size: 7px 5px;
        margin-left: 3px;
      }
      &.input_ input {
        padding: 10px 20px;
        height: 100%;
        border-bottom: 1px solid #000;
        background: url(../../../assets/medilink/input.png) left center
          no-repeat;
        background-size: 13px 13px;
        &:focus {
          appearance: none;
          border: none;
          border-bottom: 1px solid #000;
        }
      }
    }
  }
}
.noh {
  width: 1000px;
  margin: 0 auto;
  height: 500px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  img {
    width: 200px;
    margin: 0 auto;
    display: block;
    margin-bottom: 30px;
  }
}
.fixbg1 {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);
  .ant-spin-spinning {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    left: 0;
    margin: auto;
  }
}
</style>
